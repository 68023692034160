<script>
import { required, alphaNum, helpers } from "@vuelidate/validators";
import appConfig from "../../../app.config";
import axios from "axios";

export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      account: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      loading: false,
    };
  },
  validations: {
    account: {
      required: helpers.withMessage("Account is required", required),
      account: helpers.withMessage("Please enter valid account", alphaNum),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {},
  methods: {
    // 登入api
    async loginapi() {
      this.submitted = true;
      this.loading = true;

      const result = await axios
        .post(process.env.VUE_APP_BACKEND_URL + "auth/login", {
          account: this.account,
          password: this.password,
        })
        .finally(() => {
          this.loading = false;
        });

      if (result.data.status == 0) {
        return (this.authError = result.data.msg);
      }
      localStorage.setItem("jwt", result.data.data.token);
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    // 有登入的話就到首頁
    if (localStorage.getItem("jwt") != null) {
      this.$router.push({
        path: "/",
      });
    }
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <a href="index.html" class="d-inline-block auth-logo">
                  <img src="/assets/images/logo-light.png" alt="" height="20" />
                </a>
              </div>
              <p class="mt-3 fs-15 fw-medium">網站管理後台登入</p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">歡迎回來 !</h5>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="authError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div></div>

                  <form @submit.prevent="tryToLogIn">
                    <div class="mb-3">
                      <label for="account" class="form-label">帳號</label>
                      <input
                        type="text"
                        class="form-control"
                        id="account"
                        placeholder="請輸入帳號"
                        v-model="account"
                      />
                      <div class="invalid-feedback">
                        <span></span>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="password-input"
                        >密碼</label
                      >
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="password"
                          v-model="password"
                          class="form-control pe-5"
                          placeholder="請輸入密碼"
                          id="password-input"
                        />
                        <div class="invalid-feedback">
                          <span></span>
                        </div>
                      </div>
                    </div>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >記住我</label
                      >
                    </div>

                    <div class="mt-4">
                      <button
                        class="btn btn-success w-100"
                        type="submit"
                        @click="loginapi"
                        v-if="!loading"
                      >
                        登入
                      </button>

                      <button
                        class="btn btn-success w-100"
                        type="submit"
                        disabled
                        v-if="loading"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} 新澄資訊有限公司 版權所有
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
